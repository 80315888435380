import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Nakama from "./Nakama.js"
import './App.css';


const App = () => {
  const [scores, setScores] = useState([2, 2]);
  const [isStarted, setStarted] = useState(false);
  const [board, setBoard] = useState(Array(8).fill(null).map(() => Array(8).fill(null)));
  const [currentPlayer, setCurrentPlayer] = useState('black');
  const [gameOver, setGameOver] = useState(false);

  useEffect(() => {
    const username = 'player_one'; // Replace with a method to get username

    const loginAndConnect = async () => {
      try {
        await Nakama.authenticate();
        console.log(Nakama.socket);
      } catch (error) {
        console.error('Error during login and connection:', error);
      }
    };

    loginAndConnect();

  }, []);

  useEffect(() => {
    if (Nakama.socket) {
      (async () => {
        try {
          Nakama.socket.onmatchdata = (result) => {
            let jsonString = new TextDecoder().decode(result.data);
            const info = jsonString !== "" ? JSON.parse(jsonString) : {};

            switch (result.op_code) {
              case 2:
                let blackCount = 0;
                let whiteCount = 0;
                const newBoard = Array(8).fill(null).map(() => Array(8).fill(null));
                info?.board?.forEach((row, i1) => {
                  row.forEach((col, i2) => {
                    if (col === 0) {
                      newBoard[i1][i2] = 'white';
                      whiteCount++;
                    } else if (col === 1) {
                      newBoard[i1][i2] = 'black';
                      blackCount++;
                    } else {
                      newBoard[i1][i2] = null;
                    }
                  })
                });

                setBoard(newBoard);
                setScores([blackCount, whiteCount]);
                setCurrentPlayer(info.boardItemToPlay === 0 ? 'white' : 'black');
                break;
              case 3:
                const isPlayerWin = info.playerBoardItem[info.winner] === 1 ? true : false;

                if (isPlayerWin) {
                  toast.success("You won the match!");
                } else {
                  toast.success("AI won the match!");
                }
              case 5:
                toast(info.message, { type: "error" });
              default:
                break;
            }
          };
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [Nakama.socket]);

  const renderBoard = () => {
    return board.map((row, rowIndex) => (
      <div key={rowIndex} className="row">
        {row.map((cell, colIndex) => (
          <div
            key={colIndex}
            className={`cell ${cell || ''}`}
            onClick={() => !gameOver && makeMove(rowIndex, colIndex)}
          >
            {cell && <div className={`piece ${cell}`} />}
          </div>
        ))}
      </div>
    ));
  };

  const makeMove = async (row, col) => {
    await Nakama.makeMove(row, col);
  };

  const startMatch = async () => {
    try {
      await Nakama.findMatch();
      setStarted(true);
    } catch (error) {
      console.log(error);
      setStarted(false);
    }
  }

  return (
    <div className="othello">
      <h1 style={{ fontWeight: "bolder", fontSize: `${isStarted ? "4vw" : "8vw"}` }}>Othello Game</h1>
      {!isStarted && <>
        <div>
          <button onClick={() => startMatch()} className='startgamebutton'> Start Match </button>
        </div>
        <br></br>
      </>}
      {
        isStarted &&
        <>
          <div style={{ width: "29.5vw", height: "15vh", "display": "flex", justifyContent: "space-between", border: "2px solid black", padding: "0px 4vw", borderRadius: "1vw", backgroundColor: "yellowgreen", marginBottom: "1vh" }}>
            <div>
              <p style={{ textAlign: "center", fontSize: "1.3vw" }}>
                <span>
                  <div style={{
                    width: '2.9vw',
                    height: '5.7vh',
                    borderRadius: '50%',
                    backgroundColor: 'black',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '0.8vw',
                  }}></div>
                </span>
                <strong>Alice: {scores[0]} Pts</strong>
                <span style={{ fontSize: "1.1vw" }}>
                  {currentPlayer === "black" && " (Your Turn) "}
                </span>
              </p>

            </div>
            <div>
              <p style={{ textAlign: "center", fontSize: "1.3vw" }}>
                <span>
                  <div style={{
                        width: '2.9vw',
                        height: '5.7vh',
                    borderRadius: '50%',
                    backgroundColor: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '0.8vw',
                  }}></div>
                </span>
                <strong>Bob AI: {scores[1]} Pts</strong>
                <span style={{ fontSize: "1.1vw" }}>
                  {currentPlayer === "white" && " (AI Turn) "}
                </span>

              </p>

            </div>
          </div>
          <br></br>
          <div className="board">
            {renderBoard()}
          </div>
        </>
      }
      <ToastContainer />
    </div>
  );
};

export default App;