import { Client } from "@heroiclabs/nakama-js";
import { v4 as uuidv4 } from "uuid";

class Nakama {
    client;
    session;
    socket;
    matchID;

    async authenticate() {
        this.client = new Client("defaultkey", "nakama.dev.outplay.games", "443", true);
        //this.client.ssl = false;

        let deviceId = localStorage.getItem("deviceId");
        if (!deviceId) {
            deviceId = uuidv4();
            localStorage.setItem("deviceId", deviceId);
        }

        this.session = await this.client.authenticateDevice(deviceId, true);
        localStorage.setItem("user_id", this.session.user_id);

        const trace = false;
        this.socket = this.client.createSocket(true, trace);
        await this.socket.connect(this.session);

    }

    async findMatch() {
        const rpcid = "othello_find_match_js";
        const matches = await this.client.rpc(this.session, rpcid, { mode: 1 });

        this.matchID = matches.payload.matchIds[0];
        await this.socket.joinMatch(this.matchID)
        console.log("Matched joined: ", this.matchID)
    }

    async makeMove(row, col, isSkip = false) { // ep4
        var data = { "position": [row, col], skipTurn: isSkip };
        await this.socket.sendMatchState(this.matchID, 4, JSON.stringify(data));
        console.log("Match data sent")
    }
}

export default new Nakama();
